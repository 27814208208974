import classNames from 'classnames';
import styles from './styles.module.css';
type BadgeProps = {
  label: string;
  backgroundColor: string;
  labelColor?: string;
};
export const Badge = (props: BadgeProps) => {
  return <span aria-label='card badge' className={classNames(styles.badge, styles.primary)} style={{
    backgroundColor: props.backgroundColor,
    color: props.labelColor
  }} data-sentry-component="Badge" data-sentry-source-file="index.tsx">
			{props.label}
		</span>;
};